<template>
  <v-container fluid class="down-top-padding dashboardwrapper">
    <v-row>
      <v-col cols="12" lg="3">
        <v-card outlined>
          <v-layout justify-center>
            <v-icon color="primary" size="98"
              >mdi-account-multiple-outline</v-icon
            >
          </v-layout>
          <v-layout justify-center>
            <h3 class="p-5 justify-center">
              {{ $t("dashboard.totalMembers") }}
            </h3>
          </v-layout>
          <v-layout justify-center>
            <h1 style="color: green">
              {{ dashboardStatus.members_count }}
            </h1>
          </v-layout>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <v-card outlined>
          <v-layout justify-center>
            <v-icon color="primary" size="98"
              >mdi-account-multiple-outline</v-icon
            >
          </v-layout>
          <v-layout justify-center>
            <h3 class="p-5 justify-center">
              {{ $t("dashboard.totalAssociates") }}
            </h3>
          </v-layout>
          <v-layout justify-center>
            <h1 style="color: green">
              {{ dashboardStatus.associates_count }}
            </h1>
          </v-layout>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <v-card outlined>
          <v-layout justify-center>
            <v-icon color="primary" size="98"
              >mdi-account-multiple-outline</v-icon
            >
          </v-layout>
          <v-layout justify-center>
            <h3 class="p-5 justify-center">
              {{ $t("dashboard.totalLeads") }}
            </h3>
          </v-layout>
          <v-layout justify-center>
            <h1 style="color: green">
              {{ dashboardStatus.leads_count }}
            </h1>
          </v-layout>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <v-card outlined>
          <v-layout justify-center>
            <v-icon color="primary" size="98"
              >mdi-account-multiple-outline</v-icon
            >
          </v-layout>
          <v-layout justify-center>
            <h3 class="p-5 justify-center">
              {{ $t("dashboard.totalInventoryParts") }}
            </h3>
          </v-layout>
          <v-layout justify-center>
            <h1 style="color: green">
              {{ dashboardStatus.total_inventory_parts }}
            </h1>
          </v-layout>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" lg="3">
        <v-card outlined>
          <v-layout justify-center>
            <v-icon color="primary" size="98"
              >mdi-account-multiple-outline</v-icon
            >
          </v-layout>
          <v-layout justify-center>
            <h3 class="p-5 justify-center">
              {{ $t("dashboard.totalIrregularMembers") }}
            </h3>
          </v-layout>
          <v-layout justify-center>
            <h1 style="color: green">
              {{ dashboardStatus.inventory_count }}
            </h1>
          </v-layout>
        </v-card>
      </v-col> -->
      <!-- <v-col cols="12" lg="3">
        <v-card outlined>
          <v-layout justify-center>
            <v-icon color="primary" size="98">mdi-cash</v-icon>
          </v-layout>
          <v-layout justify-center>
            <h3 class="p-5 justify-center">
              {{ $t("dashboard.receivedPayments") }}
            </h3>
          </v-layout>
          <v-layout justify-center>
            <h1 style="color: green">
              $ {{ dashboardStatus.received_payments }}
            </h1>
          </v-layout>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <v-card outlined>
          <v-layout justify-center>
            <v-icon color="primary" size="98">mdi-cash</v-icon>
          </v-layout>
          <v-layout justify-center>
            <h3 class="p-5 justify-center">
              {{ $t("dashboard.pendingPayments") }}
            </h3>
          </v-layout>
          <v-layout justify-center>
            <h1 style="color: green">
              $ {{ dashboardStatus.pending_payments }}
            </h1>
          </v-layout>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <v-card outlined>
          <v-layout justify-center>
            <v-icon color="primary" size="98">mdi-cash</v-icon>
          </v-layout>
          <v-layout justify-center>
            <h3 class="p-5 justify-center">
              {{ $t("dashboard.totalPayments") }}
            </h3>
          </v-layout>
          <v-layout justify-center>
            <h1 style="color: green">$ {{ dashboardStatus.total_payments }}</h1>
          </v-layout>
        </v-card>
      </v-col>-->
    </v-row>
    <v-row>
      <v-col cols="12" lg="12">
        <v-card>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4"></div>
            </v-list-item-content>
            <h1 style="color: white">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    v-model="startDate"
                    :label="$t('dashboard.startDate')"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="green darken-4"
                  v-model="starts_at"
                  @input="menu = false"
                  @change="initialize"
                >
                </v-date-picker>
              </v-menu>
            </h1>
            <h1 style="color: white" class="pl-7">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    v-model="endDate"
                    :label="$t('financial.endDate')"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="green darken-4"
                  v-model="ends_at"
                  @input="menu2 = false"
                  @change="initialize"
                >
                </v-date-picker>
              </v-menu>
            </h1>
          </v-list-item>
          <v-row>
            <v-col cols="12" lg="3">
              <v-card outlined>
                <v-layout justify-center>
                  <v-icon color="primary" size="98"
                    >mdi-credit-card-search</v-icon
                  >
                </v-layout>
                <v-layout justify-center>
                  <h3 class="p-5 justify-center">
                    {{ $t("dashboard.totalVinDecoder") }}
                  </h3>
                </v-layout>
                <v-layout justify-center>
                  <h1 style="color: green">
                    {{ dashboardStatus.total_vin_decoder }}
                  </h1>
                </v-layout>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card outlined>
                <v-layout justify-center>
                  <v-icon color="primary" size="98"
                    >mdi-car</v-icon
                  >
                </v-layout>
                <v-layout justify-center>
                  <h3 class="p-5 justify-center">
                    {{ $t("dashboard.totalCarHistory") }}
                  </h3>
                </v-layout>
                <v-layout justify-center>
                  <h1 style="color: green">
                    {{ dashboardStatus.total_car_history }}
                  </h1>
                </v-layout>
              </v-card>
            </v-col>
            <!-- <col cols="12" lg="3">
              <v-card outlined>
                <v-layout justify-center>
                  <v-icon color="primary" size="98"
                    >mdi-credit-card-search</v-icon
                  >
                </v-layout>
                <v-layout justify-center>
                  <h3 class="p-5 justify-center">
                    {{ $t("dashboard.totalVinSearch") }}
                  </h3>
                </v-layout>
                <v-layout justify-center>
                  <h1 style="color: green">
                    {{ dashboardStatus.vin_numbers_count }}
                  </h1>
                </v-layout>
              </v-card>
            </col>
            <v-col cols="12" lg="3">
              <v-card outlined>
                <v-layout justify-center>
                  <v-icon color="primary" size="98"
                    >mdi-book-open-variant</v-icon
                  >
                </v-layout>
                <v-layout justify-center>
                  <h3 class="p-5 justify-center">
                    {{ $t("dashboard.booklogMembersCount") }}
                  </h3>
                </v-layout>
                <v-layout justify-center>
                  <h1 style="color: green">
                    {{ dashboardStatus.book_log_users_count }}
                  </h1>
                </v-layout>
              </v-card>
            </v-col>v- -->
            <v-col cols="12" lg="3">
              <v-card outlined>
                <v-layout justify-center>
                  <v-icon color="primary" size="98">mdi-post</v-icon>
                </v-layout>
                <v-layout justify-center>
                  <h3 class="p-5 justify-center">
                    {{ $t("dashboard.postingCount") }}
                  </h3>
                </v-layout>
                <v-layout justify-center>
                  <h1 style="color: green">
                    {{ dashboardStatus.total_posts }}
                  </h1>
                </v-layout>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card outlined>
                <v-layout justify-center>
                  <v-icon color="primary" size="98">mdi-message</v-icon>
                </v-layout>
                <v-layout justify-center>
                  <h3 class="p-5 justify-center">
                    {{ $t("dashboard.smsPostingCount") }}
                  </h3>
                </v-layout>
                <v-layout justify-center>
                  <h1 style="color: green">
                    {{ dashboardStatus.total_sms_posts }}
                  </h1>
                </v-layout>
              </v-card>
            </v-col>
            <!--<v-col cols="12" lg="4">
              <v-card outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="overline mb-4"></div>
                    <v-list-item-title class="headline p-5">
                      {{ $t("dashboard.interchangeSearchCount") }}
                    </v-list-item-title>
                    <v-list-item-subtitle></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar tile size="80" color="primary"
                    ><h1 style="color: white">
                      {{ dashboardStatus.leads_count }}
                    </h1></v-list-item-avatar
                  >
                </v-list-item>
              </v-card>
            </v-col> -->
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { showSnackbar } from "../eventbus/action.js";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "BasicDashboard",
  computed: {
    ...mapGetters({
      dashboardStatus: "dashboard/getDashbaordStatus",
    }),
    startDate() {
      return this.starts_at ? moment(this.starts_at).format("MM/DD/YY") : "";
    },
    endDate() {
      return this.ends_at ? moment(this.ends_at).format("MM/DD/YY") : "";
    },
  },
  data: () => ({
    starts_at: moment().subtract(1, "month").format("yyyy-MM-DD"),
    ends_at: moment().format("yyyy-MM-DD"),
    menu: false,
    menu2: false,
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions({
      dashboard: "dashboard/getDashbaordStatus",
    }),

    async initialize() {
      try {
        await this.dashboard({
          start_date: this.starts_at,
          end_date: this.ends_at,
        });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      }
    },
  },
};
</script>
